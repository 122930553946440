import React from 'react'
import Layout from '../../../components/help/Layout/Layout'
import Seo from '../../../components/help/Seo'
import Header from '../../../components/ui/Header/Header'
import HeaderFixed from './../../../components/ui/HeaderFixed/HeaderFixed'
import Intro from '../../../components/ui/Intro/Intro'
import News from '../../../components/pages/News/News'
import PageNavigation from '../../../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../../../utils/getHrefToHome'
import { useStaticQuery, graphql } from 'gatsby'

const NewsPage = () => {
    const lang = 'en'

    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            items: allDatoCmsRenshinNew(sort: { fields: date, order: DESC }, filter: { locale: { eq: "en" } }) {
                nodes {
                    date(formatString: "")
                    urlCode
                    title
                    previewText
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Press center" }, locale: { eq: "en" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                bgTabletA {
                    url
                }
                bgTabletP {
                    url
                }

                bgMobile {
                    url
                }
                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                crumbsName
            }
        }
    `)

    const photo = {
        1920: data.seo.bgDesktop.url,
        1440: data.seo.bgDesktop.url,
        1024: data.seo.bgTabletA?.url,
        768: data.seo.bgTabletP?.url,
        360: data.seo.bgMobile?.url
    }

    const breadCrumbs = [
        { name: `${data.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo
                title={data.seo.seo && data.seo.seo.title ? data.seo.seo.title : ''}
                description={data.seo.seo && data.seo.seo.description ? data.seo.seo.description : ''}
            />

            <Header light lang={lang} />
            <HeaderFixed lang={lang} />

            <Intro crumbs={breadCrumbs} title={`${data.seo.titleH1}`} photo={photo} lang={lang} />
            <News list={data.items.nodes} lang={lang} />
            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default NewsPage
